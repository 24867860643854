import {
  MOSS_100,
  OwnUpExtraSmallHeadlineBook,
  OwnUpGridContainer,
  OwnUpGridWrapper,
  OwnUpHeadlineBook,
  OwnUpThemeProvider
} from '@rategravity/own-up-component-library';
import React from 'react';
import { Ankle } from '../components/ankle';
import { HaveQuestions } from '../components/have-questions';
import { Layout } from '../components/layout';
import { Divider } from '../components/reskin-divider';
import {
  DividerWrapper,
  SectionBody,
  SectionSubheading,
  TermsContent,
  TermsTimeStamp,
  TermsWrapper
} from '../components/terms-and-conditions/elements';
import { termsSections } from '../data/content/terms-conditions';

const mappedTermsCopy = (section: any[]) => {
  return section.map(({ heading, subheading, body }, i) => {
    const keyIdentifier = heading || subheading;
    return (
      <div key={`${keyIdentifier}` + i}>
        {heading && (
          <OwnUpExtraSmallHeadlineBook variant="h2">{heading}</OwnUpExtraSmallHeadlineBook>
        )}
        {subheading && <SectionSubheading variant="body1">{subheading}</SectionSubheading>}
        {body && <SectionBody>{body.map((paragraph: JSX.Element) => paragraph)}</SectionBody>}
      </div>
    );
  });
};

const mappedTerms = () => {
  return termsSections.map((section, i) => (
    <OwnUpGridContainer variant="legacy" key={i}>
      <TermsContent>{mappedTermsCopy(section)}</TermsContent>
      {i + 1 !== termsSections.length ? (
        <DividerWrapper>
          <Divider />
        </DividerWrapper>
      ) : null}
    </OwnUpGridContainer>
  ));
};

const TermsAndConditions = () => {
  return (
    <Layout>
      <OwnUpThemeProvider>
        <TermsWrapper>
          <OwnUpGridWrapper>
            <OwnUpGridContainer variant="legacy">
              <TermsContent>
                <OwnUpHeadlineBook variant="h1">Terms and Conditions</OwnUpHeadlineBook>
                <TermsTimeStamp variant="body1">Last Updated: March 6, 2024</TermsTimeStamp>
              </TermsContent>
            </OwnUpGridContainer>
            {mappedTerms()}
          </OwnUpGridWrapper>
        </TermsWrapper>
        <HaveQuestions backgroundColor={MOSS_100} address />
        <Ankle />
      </OwnUpThemeProvider>
    </Layout>
  );
};

export default TermsAndConditions;
